<template>
  <div class="lobby" v-if="eventIsLoaded && !customUiIsLoading">
    <div
      class="lobby__background"
      :style="{ 'background-image': 'url(' + event.lobbyBackground + ')' }"
    >
      <v-container
        class="d-flex justify-center align-center fill-height lobby__container px-0 py-0"
        fluid
      >
        <v-row justify="center" align="center">
          <v-col cols="12" sm="8" class="lobby__content">
            <div class="d-flex justify-center">
              <img
                class="contents__event-logo hidden-sm-and-down"
                :src="event.logoWeb"
                :alt="event.name"
              />
              <img
                class="contents__event-logo hidden-md-and-up"
                :src="event.logoMobile"
                :alt="event.name"
              />
            </div>

            <images-carousel-description
              v-if="eventHasLobbyImages"
              class="ma-2"
              :items="event.options.eventLobbyOptions.imageOptions"
            />

            <v-sheet dark class="d-flex justify-center flex-column lobby__body">
              <menu-buttons
                class="pa-lg-5 pt-5"
                :menu="menu"
                :isLoggedIn="isLoggedIn"
                :isCustomUi="true"
              />

              <v-divider v-if="!!menu.length" class="my-4" color="grey" />

              <custom-buttons class="pa-lg-5 pt-5 pb-5" />

              <v-divider v-if="eventHasExternalLinks" class="my-4" color="grey" />

              <custom-external-links-buttons class="pa-lg-5 pt-5 pb-5" />

              <sponsors :partners="event.partners" :showPlatinium="true" :includeOther="true" />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>

  <loading v-else />
</template>

<script>
import { mapGetters } from 'vuex';

import CustomButtons from '@/components/layout/main-menu/custom-buttons/CustomButtons.vue';
import Loading from '@/components/loading/Loading.vue';
import Sponsors from '@/components/sponsors/Sponsors.vue';

import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';
import { SHARED_AUTH_MODULE } from '@/stores/shared/actions/auth/auth.actions';

import MenuUtil from '@/helpers/menu/menu.helper';
import MenuButtons from '@/components/layout/main-menu/menu-buttons/MenuButtons.vue';
import CustomExternalLinksButtons from '@/components/layout/main-menu/custom-buttons/CustomExternalLinksButtons.vue';
import ImagesCarouselDescription from '@/components/images-carousel-description/ImagesCarouselDescription.vue';

export default {
  name: 'Lobby',
  components: {
    CustomButtons,
    Loading,
    Sponsors,
    MenuButtons,
    CustomExternalLinksButtons,
    ImagesCarouselDescription,
  },
  data: () => ({
    menu: [],
  }),
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['event', 'eventIsLoaded']),
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUiIsLoading']),
    ...mapGetters(SHARED_AUTH_MODULE, ['isLoggedIn']),
    eventHasExternalLinks() {
      return !!this.event?.externalLinks?.length;
    },
    eventHasLobbyImages() {
      return !!this.event?.options?.eventLobbyOptions?.imageOptions?.length;
    },
  },
  async mounted() {
    this.menu = await MenuUtil.getMenu(this.$route);
    this.menu = this.menu
      ? this.menu.filter(({ name, displayed }) => name !== 'lobby' && displayed)
      : [];
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.lobby {
  height: 100%;

  &__container {
    height: 100%;
  }

  &__title {
    padding: 1rem 0 0;
    color: #ffffff;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 25px;
    min-height: calc(100vh - 6rem);
  }

  &__body {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin: 0 -1rem;
  }

  &__background {
    min-height: 100%;
    background-position: bottom center;
    background-size: cover;
    background-attachment: fixed;
  }

  .contents__event-logo {
    object-fit: contain;
    max-width: 90vw;
    max-height: 80vh;
  }

  .custom-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & ::v-deep .button__wrapper {
      flex: 1 0 100% !important;
      min-width: 100%;
      padding: 10px;
    }

    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  ::v-deep #sponsors {
    background-color: transparent;
  }

  @include breakpoint(small) {
    .custom-buttons {
      & ::v-deep .button__wrapper {
        flex: 1 0 50% !important;
        max-width: 50%;
        padding: 10px;
        min-width: 0 !important;
      }

      &__body {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: inherit;
      }
    }
  }

  @include breakpoint(medium) {
    .custom-buttons {
      & ::v-deep .button__wrapper {
        flex: 1 0 33% !important;
        max-width: 33%;
        min-width: 0 !important;
        padding: 10px;
      }
    }
  }
}
</style>
