<template>
  <v-carousel class="image-carousel-description" cycle continuous show-arrows-on-hover>
    <v-carousel-item cover v-for="(item, i) in items" :key="i" :src="item.imageUrl">
      <v-container fill-height fluid pa-0 ma-0 pb-3>
        <v-layout v-if="item.description" fill-height align-end pb-4 mb-4>
          <v-flex xs12>
            <v-card class="pa-2" :color="accentColor">
              <span :color="textColor" v-html="item.description"> </span>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { mapGetters } from 'vuex';

import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';

export default {
  name: 'ImagesCarouselDescription',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUiIsLoading', 'customUi']),

    accentColor() {
      return this.customUi?.btnColor || 'primary';
    },
    textColor() {
      return this.customUi?.btnTextColor || '#FFFFFF';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';
</style>
